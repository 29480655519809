<template>
  <div class="editNicknameBox">
    <div class="editNickname">
      <div class="userBox">
        <section class="itemBox newItemBox">
          <p class="itemBoxLeft">{{ $t.auth.inputNewName }}</p>
          <input
            class="itemBoxRight"
            v-model="newNameText"
            :placeholder="$t.auth.inputYourName"
          />
        </section>
      </div>
    </div>
    <div class="submit-btn" @click="onSubmit">{{ $t.meta.submit }}</div>
  </div>
</template>

<script>
export default {
  name: "EditNickname",
  data() {
    return {
      newNameText: "",
    };
  },
  methods: {
    onSubmit() {
      const name = this.newNameText;
      if (name) {
        let param = {
          NickName: name,
        };
        this.$api.user
          .EditUserInfo(param)
          .then((res) => {
            this.$commonEnv.commonAction(
              this.$commonEnv.actionType.showNotify,
              {
                titleMsg: res.message,
                type: "warning",
              }
            );
            this.$commonEnv.commonAction(
              this.$commonEnv.actionType.routerPush,
              {
                isParams: false,
                path: "user/userManage",
                name: "UserManage",
                query: [],
              }
            );
          })
          .catch((err) => {
            this.$commonEnv.commonAction(
              this.$commonEnv.actionType.showNotify,
              {
                titleMsg: err.message,
                type: "danger",
              }
            );
          });
      } else {
        this.$commonEnv.commonAction(this.$commonEnv.actionType.showNotify, {
          type: "danger",
          titleMsg: this.$t.auth.inputYourName,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~assets/scss/publicStyle.scss";
@mixin newBox1 {
  border-radius: 8px;
  background-color: #ffffff;
  margin-top: 0.8rem;
}
@mixin boxLeft {
  max-width: 35%;
  text-align: left;
  color: #000000;
}
@mixin boxright {
  max-width: 65%;
  text-align: right;
  font-size: 0.8rem;
  color: #000000;
  font-weight: bold;
  background: none;
  outline: none;
  border: none;
}
.editNicknameBox {
  width: 100%;
  padding-bottom: 75px;
  .editNickname {
    font-size: 0.8rem;
    margin: 0 auto;
    width: 92%;
    .userBox {
      @include newBox1;
      .itemBox {
        justify-content: space-between;
        -webkit-justify-content: space-between;
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        padding: 18px 15px;
        @include publicFlex;
        .itemBoxLeft {
          @include boxLeft;
        }
        .itemBoxRight {
          @include boxright;
        }
        .address {
          width: 100%;
          border: none;
        }
      }
      .newItemBox {
        border-bottom: 1px solid #f6f6f6;
      }
    }
  }
  .submit-btn {
    margin: auto;
    width: 80%;
    border-radius: 3rem;
    height: 40px;
    line-height: 40px;
    text-align: center;
    color: #fff;
    font-size: 1rem;
    font-weight: bold;
    background: -webkit-linear-gradient(left, #71d283, #01aaa3);
    margin-top: 20px;
  }
}
</style>